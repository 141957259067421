<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card
          style="height: 90%"
          no-body
        >
          <b-card-header>
            <!-- title and legend -->
            <b-card-title>
              Mantenimiento Preventivo Mensual
            </b-card-title>
            <!--/ title and legend -->
          </b-card-header>

          <b-card-body>
            <vue-apex-charts
              style="margin-top: -20px"
              type="bar"
              height="400"
              :options="apexChatData.columnChart.chartOptions"
              :series="chartData"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BCardBody, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from '@/utils/mantenimiento/informe-mensual/apexChartData'
import { findMetricasInforme } from '@/utils/administrador/metricas'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    VueApexCharts,
  },
  props: {
    mes: Object,
    año: Object,
  },
  data() {
    return {
      usuario: JSON.parse(localStorage.getItem('userData')),
      apexChatData,
      chartData: [],
    }
  },
  watch: {
    mes: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.loadReport()
        }
      },
    },
    año: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.loadReport()
        }
      },
    },
  },
  created() {
    this.loadReport()
  },
  methods: {
    async loadReport() {
      this.show = true
      const idCompanyUsuario = this.usuario.idCompany
      const mes = this.mes ? this.mes.id : null
      const año = this.año ? this.año.id : null

      try {
        const tableData = await findMetricasInforme(idCompanyUsuario, mes, año)

        if (tableData) {
          this.chartData = [
            {
              name: 'Meta Mensual',
              data: tableData.map(item => item.metrica),
            },
            {
              name: 'Métrica Actual',
              data: tableData.map(item => item.trabajos),
            },
          ]

          const formularios = tableData.map(item => item.formulario)
          this.apexChatData.columnChart.chartOptions.xaxis.categories = formularios
        } else {
          this.chartData = []
          this.apexChatData.columnChart.chartOptions.xaxis.categories = []
        }
      } catch (error) {
      } finally {
        this.show = false
      }
    },
  },
}
</script>

<style scoped>

</style>
