<template>
  <div>
    <b-overlay :show="show">
      <b-row style="display: flex; justify-content: end">
        <b-col cols="3">
          <label>Mes: </label>
          <v-select
            id="vue-select"
            v-model="mes"
            :options="mesesDate"
            label="nombre"
          />
        </b-col>
        <b-col cols="3">
          <label>Año: </label>
          <v-select
            id="vue-select"
            v-model="año"
            :options="años"
            label="nombre"
          />
        </b-col>
        <b-col style="padding-top: 3px" cols="1">
          <br />
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.v-primary
            title="Buscar"
            variant="primary"
            class="btn-icon"
            @click="loadReport()"
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col cols="12">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            style-class="vgt-table condensed"
            :search-options="{
              enabled: false,
              placeholder: 'Buscar',
            }"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'noInforme'">
                &nbsp;&nbsp;{{ props.index + 1 }}.
              </span>
              <span v-else-if="props.column.field === 'diferencia'">
                <b-badge :variant="getDiferenciaVariant(props.row.diferencia)">
                  {{ getDiferenciaAbs(props.row.diferencia) }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'metrica'">
                <p style="font-weight: bold" class="text-primary">
                  {{ props.row.metrica }}
                </p>
              </span>
              <span v-else-if="props.column.field === 'trabajosRealizados'">
                <p style="font-weight: bold" class="text-primary">
                  {{ props.row.trabajos }}
                </p>
              </span>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col cols="12">
          <mantenimiento-preventivo-chart :mes="mes" :año="año" />
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCol,
  BOverlay,
  BRow,
  VBModal,
  VBPopover,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import { getListadoMeses } from "@/utils/fechas";
import {
  generarReporte,
  getInforme,
} from "@/utils/mantenimiento/informe-mensual/informeMensualUtils";
import Ripple from "vue-ripple-directive";
import { findMetricasInforme } from "@/utils/administrador/metricas";
import MantenimientoPreventivoChart from "@/components/charts/mantenimiento-preventivo-chart.vue";

export default {
  components: {
    MantenimientoPreventivoChart,
    BButton,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BOverlay,
    VueGoodTable,
    BBadge,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },
  data() {
    return {
      show: false,
      columns: [
        {
          label: "No.",
          field: "noFormulario",
        },
        {
          label: "Actividad",
          field: "formulario",
        },
        {
          label: "Metrica Según Contrato",
          field: "metrica",
          tdClass: "text-center",
          thClass: "vgt-center-align",
        },
        {
          label: "Trabajos Realizados",
          field: "trabajosRealizados",
          tdClass: "text-center",
          thClass: "vgt-center-align",
        },
        {
          label: "Diferencia",
          field: "diferencia",
          tdClass: "text-center",
          thClass: "vgt-center-align",
        },
      ],
      rows: [],
      usuario: JSON.parse(localStorage.getItem("userData")),
      meses: [
        {
          short: "Ene",
          nombre: "Enero",
          numero: 1,
          checked: false,
        },
        {
          short: "Feb",
          nombre: "Febrero",
          numero: 2,
          checked: false,
        },
        {
          short: "Mar",
          nombre: "Marzo",
          numero: 3,
          checked: false,
        },
        {
          short: "Abr",
          nombre: "Abril",
          numero: 4,
          checked: false,
        },
        {
          short: "May",
          nombre: "Mayo",
          numero: 5,
          checked: false,
        },
        {
          short: "Jun",
          nombre: "Junio",
          numero: 6,
          checked: false,
        },
        {
          short: "Jul",
          nombre: "Julio",
          numero: 7,
          checked: false,
        },
        {
          short: "Ago",
          nombre: "Agosto",
          numero: 8,
          checked: false,
        },
        {
          short: "Sep",
          nombre: "Septiembre",
          numero: 9,
          checked: false,
        },
        {
          short: "Oct",
          nombre: "Octubre",
          numero: 10,
          checked: false,
        },
        {
          short: "Nov",
          nombre: "Noviembre",
          numero: 11,
          checked: false,
        },
        {
          short: "Dic",
          nombre: "Diciembre",
          numero: 12,
          checked: false,
        },
      ],
      año: null,
      mes: null,
      años: [],
      mesesDate: [],
    };
  },
  async created() {
    this.años = getListadoMeses(2);
    this.mesesDate = getListadoMeses(1);
    const fechaActual = new Date();
    const mesActual = fechaActual.getMonth() + 1;
    const añoActual = fechaActual.getFullYear().toString();
    this.mes = this.mesesDate.find((mes) => mes.id === mesActual.toString());
    this.año = this.años.find((año) => año.id === añoActual);
    this.rows = [];
    await this.loadReport();
    this.show = false;
  },
  methods: {
    getDiferenciaVariant(diferencia) {
      return diferencia < 0 ? "light-danger" : "light-success";
    },
    getDiferenciaAbs(diferencia) {
      return Math.abs(diferencia);
    },
    async generarDocumento(id) {
      try {
        const informe = await getInforme(id);
        const fileResumen = await generarReporte(informe);
        if (fileResumen !== null) {
          const linkSource = `data:application/pdf;base64,${fileResumen.Base64}`;
          const downloadLink = document.createElement("a");
          const fileName = "Conservación de Gateway.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async loadReport() {
      this.show = true;
      const idCompanyUsuario = this.usuario.idCompany;
      const month = this.mes ? this.mes.id : null;
      const year = this.año ? this.año.id : null;

      if (month && year)
        this.rows = await findMetricasInforme(idCompanyUsuario, month, year);
      this.show = false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
