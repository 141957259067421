// colors
const chartColors = {
  column: {
    series1: '#1479cc',
    series2: '#81c0f1',
    bg: 'rgba(218,218,218,0.34)',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  primary: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series3: '#a4f8cd',
    series2: '#60f2ca',
    series1: '#2bdac7',
  },
}

export default {
  columnChart: {
    series: [
      {
        name: 'Meta Mensual',
        data: [100, 100, 100, 90, 90, 90, 90, 90],
      },
      {
        name: 'Meta Actual',
        data: [100, 100, 100, 100, 100, 100, 100, 100],
      },
    ],
    chartOptions: {
      chart: {
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      colors: [chartColors.column.series1, chartColors.column.series2],
      plotOptions: {
        bar: {
          columnWidth: '25%',
          colors: {
            backgroundBarColors: [
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
            ],
            backgroundBarRadius: 0,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: 'top',
        fontSize: '15px',
        fontFamily: 'Montserrat',
        horizontalAlign: 'left',
      },
      stroke: {
        show: true,
        colors: ['transparent'],
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: ['Cord. Aseguradora', 'Fallas BT', 'Inspecciones', 'Conservación', 'Brecha Iluminación', 'Cons. Gateway', 'Cons. Gabinetes', 'Aplomado Poste'],
      },
      yaxis: {
        // opposite: isRtl,
      },
      fill: {
        opacity: 1,
      },
    },
  },
}
